$baby-powder: #F9F6F6;
$cream: #F9F4E9;
$light-grey: #F6F6FC;
$dark-blue: #172C3C;
$tan: #E2CDB5;
$brown: #B97853;
$light-green: #CCD6A5;
$medium-green: #8EC3A2;
$dark-green: #0E1806;
$headshot-green: #99D9B7;
$white-overlay: linear-gradient(rgba(255,255,255,0.9),rgba(255,255,255,0.88));
$black-overlay:linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7));

$body: 'Open Sans', sans-serif;
$extra-bold: 800;

// MISC PAGE STYLES

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  background-color: black;
}
.site-wrapper{
  background-color: white;
  font-family: $body;
  font-weight: light;
}
.container{
  padding-top: 10vh;
  margin-top: -10vh;
}

// Headings
h2 {
  font-family: $body;
  font-weight: bold;
  margin-top: 2rem;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: $medium-green;
}
h3 {
  color: $medium-green;
}
header{
  margin: 3rem;
  p{
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

// Buttons
.btn {
  -webkit-transition: color 0.25s;
  transition: color 0.25s;
  font-size: 1.25rem;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 8px;
  width: 200px;
  padding: 10px;
  background-color: $baby-powder;
  margin: 2rem;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.call-btn{
  width: 300px;
  background-color: $medium-green;
  border: none;
  -webkit-transition: filter 0.25s;
  transition: filter 0.25s;
}

.call-text{
  color: white;
}

.btn-flex{
  display: flex;
  justify-content: center;
}
.btn:hover {
  color:$medium-green;
  background-color: $baby-powder;
  border: 1px solid rgba(0,0,0,.125);
}
.call-btn:hover{
  background-color: $medium-green;
  filter: brightness(1.1);
  border: none;
}
.display-none {
  display: none;
}

// BACKGROUND IMAGES

.image-section {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 20vh;
}

@media only screen and (min-width : 992px) {
  .image-section {
    background-attachment: fixed;
}
}


.palm-1 {
  background-image: url(../assets/Backgrounds/palm-1.jpg), $black-overlay;
  background-blend-mode: overlay;
}

.palm-2 {
  background-image: url(../assets/Backgrounds/palm-2.jpg), $black-overlay;
  background-blend-mode: overlay;
}
.palm-3 {
  background-image: url(../assets/Backgrounds/palm-3.jpg), $black-overlay;
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;
}
.palm-4 {
  background-image: url(../assets/Backgrounds/palm-4.jpg), $black-overlay;
  background-blend-mode: overlay;

}
.palm-5 {
  background-image: url(../assets/Backgrounds/palm-5.jpg), $black-overlay;
  background-blend-mode: overlay;
}

// NAV BAR STYLES

nav{
  position: fixed;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  z-index: 1;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  .nav-link, .navbar-brand {
    color: $cream;
  }
  .navbar-toggler {
    background-color: $cream;
    border-radius: 20px;
  }
  .nav-link.active, .show>.nav-link, .nav-link:hover, .navbar-brand.active, .navbar-brand:hover{
    color: $medium-green !important;
  }
  .dropdown-item {
    font-family: $body;
  }
  .dropdown-menu.show {
    max-width: 100px;
  }
}

// HERO SECTION STYlES

.video-container {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
}

.content{
  margin: 0 auto;
  position: absolute;
}
.content video{
  width: 100vw;
  display: block;
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.content:before {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;  
}

.hero {
  position: absolute;
  top: 40%;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  margin: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: $baby-powder;
  h1 {
    font-weight: normal;
    font-size: 8rem;
    margin: 2rem auto;
  }
  h2, h3{
    color: $baby-powder;
  }
  .dev{
    font-size: 4rem;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
}

.hero-green{
  color: $medium-green !important;
}

@keyframes bounce {
  0% {transform: translateY(0px);}
  100% {transform: translateY(-10px);}
}

.hero-bottom{
  padding-top: 10vh;
}

.hero-large{
  display: none;
}

.hero-arrow {
  animation: bounce 0.75s ease infinite alternate;
  padding-top: 10vh;
  max-width: 75%;
  margin: 0 auto;
}

@media only screen and (min-width : 992px) {
  .hero {
    .hero-small{
      display: none;
    }
    .hero-large{
      display:block;
    }
    top: 55%;
    .hero-arrow{
      padding-top: 15vh;
    }
  }
}

// SERVICES SECTION STYLES

.services-wrapper{
  margin-top: 10vh;
    .services-text{
      margin-top: 2rem;
      font-size: 1.25rem;
      font-weight: normal;
    }
  a{
    text-decoration: none;
  }
}
.services-flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.individual-service{
  .service-icon{
    color: $medium-green;
    font-size: 3rem;
    i{
      margin-right: 2rem;
    }
  }
  width: 350px;
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media only screen and (min-width : 992px) {

}
// SKILLS SECTION STYLES

#skills {
  margin-bottom: 2rem;
  img{
    margin: 0 auto;
  }
}
.skill-icons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.skill-icons {
  li {
    justify-content: center;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 5%;
    margin: 20px 5px 5px 5px;
    width: 90px;
    height: 90px;
    align-items: center;
    i {
      font-size: 1.5rem;
    } 
  } 
  li:hover {
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  }
  span{
    font-size: .8rem;
  }
}
.cell {
  display: flex;
  flex-direction: column;
  background-color: $baby-powder;
}

@media only screen and (min-width : 992px) {
  .skill-icons li {
    margin: 20px 10px 10px 10px;
    width: 110px;
    height: 110px;
    align-items: center;
    i {
      font-size: 2rem;
    }
  }
}

// EXPERIENCE SECTION STYLES
.vertical-timeline-element-title, .vertical-timeline-element-subtitle{
  font-family: $body;
}

.vertical-timeline{
  h3{
    color: $medium-green;
  }
}
// Line color
.vertical-timeline::before {
  background: $dark-blue;
}
.vertical-timeline-element-icon {
  box-shadow: none;
}
.hide-experience {
  text-align: left;
  width: 85%;
  margin: 1rem auto;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}

.vertical-timeline-element-content:hover .hide-experience{
  max-height: 800px;
  transition: max-height 0.75s ease-in;
}

// PROJECTS SECTION STYLES

.mac-svg {
  background-image: none;
  margin: 0 auto;
  position: relative;
  width: 100%;
  min-width: 300px;
  min-height: 275px;
}

@media only screen and (min-width : 768px) {
  .mac-svg{
    background-image: url(../assets/Projects/mac-screen.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    min-height: 530px;
  }

  .carousel-container{
    width: 100%;
    max-width: 616px;
  }
}

.carousel-container{
  width: 90%;
  max-width: 616px;
  height: 275px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 4%;
}

.carousel-cell{
  height: 100%;
}

.flickity-viewport{
  height: 275px;
  img{
    max-width: 100%;
  }
}

.flickity-page-dots {
  bottom: 10px; 
}

.project {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 25px;
  background-color: $baby-powder;
  margin: 2rem 0;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  h3{
    font-weight: bold;
  }
}

.project-image {
  margin-top: 2rem;
}

.project-info {
  margin: 2rem;
}
.info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  .project-left, .project-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    .demo {
      font-size: .9rem;
    }
    h3 {
      margin-top: 1rem;
    }

    p {
      margin-top: 1rem;
    }
  }

  .project-right {
    margin-top: 1rem;
  }

  .project-icons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;

    .cell {
      background-color: white;
    }
  }
  
  .project-icons-wrapper {
    max-width: 400px;
    margin: 0 auto;
  }

  .project-icons {
    li {
    justify-content: center;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 5%;
    margin: 10px 5px 5px 5px;
    width:75px;
    height: 75px;
    align-items: center;

    i {
      font-size: 1.5rem;
      }
    span {
      font-size: 0.75rem;
    }
    }
    li:hover {
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
    }
  }
  .project-links, .project-left {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-left: 1rem;

    a {
      text-decoration: none;
      color: $dark-blue;
      -webkit-transition: color 0.25s;
      transition: color 0.25s;
      font-size: 1.25rem;
      padding-right: 20px;
    }
    p{
      a{
        padding: 0;
      }
    }
    a:hover {
      color:$medium-green;
    }
  }
}

@media only screen and (min-width : 768px) {
  .carousel-container{
    height: 350.5px;
  }

  .flickity-viewport{
    height: 350.5px;
  }

  .info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    .project-left, .project-right {
      width: 50%;
      max-width: 475px;
    }
  }

}

// ABOUT SECTION STYLES

.about-wrapper {
  h2{
    color: $dark-blue;
  }
  min-height: 100vh;
  .about-title{
    width: 80%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  header, .about-title{
    border-bottom: solid white 3px;
  }
  p {
    font-size: 1.25rem;
    margin: 1rem auto;
    max-width: 800px;
    text-align: left;
  }
}
.about-text{
  max-width: 600px;
}
.about-text, .background-text{
  p{
    font-size: 1rem;
    margin: 1rem auto;
    max-width: 75%;
  }

}
.about-flex{
  margin: 6rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.about-text{
  padding-bottom: 2rem;
}
.about-icon{
  color: $dark-blue;
  font-size: 3rem;
  margin-right: 1rem;
}
.about-icon-flex{
  display: flex;
  flex-direction: row;
  align-items: center;
}

#about, #more-about {
  a {
    color: $dark-blue;
    text-decoration: none;
    -webkit-transition: color 0.25s;
    transition: color 0.25s;
    font-size: 1.5rem;
  }
  a:hover {
    color:$medium-green !important;
  }

  @media only screen and (max-width : 576px) {
    .about-flex{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
    a {
      font-size: 2rem;
    }
    .link-label {
      display: none;
    }
    .call {
      font-size: 1.75rem;
    }
}
}

.about-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

#more-about {
  padding: 0;
  margin: 0;
  width: 100%;
}
.card{
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 25px;
  background-color: $baby-powder;
  margin: 2rem 0;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
}
.card-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.about-background, .about-education{
  p{
    font-size: 1rem;
  }
  .card-wrapper {
    max-width: 900px;
    margin: 0 auto;
  }
  .card {
    height: 210px;
    width: 270px;
    margin: 1rem auto;
    .card-img-wrapper {
      padding-top: 10px;
      height: 230px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .card-title {
      font-size: 1rem;
    }
    .card-subtitle{
      font-size: 0.75rem;
    }
    img {
      width: 100px;
      max-height: 150px;
      border: none;
      box-shadow: none;
      mix-blend-mode: darken;
    }
  }
}
.about-img{
  display: flex;
  align-items: center;
  justify-content: center;
}
.kate-pic{
  max-width: 100%;
  min-width: 275px;
}
.about-reading, .about-listening{
  text-align: center;
  .card-wrapper{
    max-width: 825px;
    margin: 0 auto;
  }
  .card {
    height: 350px;
    width: 225px;
    margin: 1rem auto;
    .card-header {
      margin-bottom: 1rem;
      background-color: $medium-green;
      color: white;
      font-size: 1.25rem;
      font-weight: 500;
    }
    .card-img-wrapper {
      height: 230px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .card-title {
      font-size: 1rem;
    }
    .card-subtitle{
      font-size: 0.75rem;
    }
    img {
      max-width: 125px;
      max-height: 200px;
    }
  }
  
}
.about-listening{
  .card{
    height: 275px;
  }
}
.about-reading, .about-listening, .about-photography{
  img {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 8px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
}

.about-photography{
  p{
    width: 80%;
  }
}
.photo-wrapper{
  background-color: black;
  padding: 2rem;
}
.hide-btn{
  background-color: black;
}
.links{
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 2rem;
  max-width: 800px;
  div{
    display: flex;
    justify-content: space-around;
    width: 100%;
    line-height: 0.5rem;
  }
  div+div{
    border-left:solid 2px $dark-blue;
  }
}
@media only screen and (min-width : 992px) {
  .about-flex{
    background-image: url(../assets/Backgrounds/palm-4.jpg), $white-overlay;
    filter: grayscale(100%);
    background-blend-mode: lighten;
    background-repeat: no-repeat;
    background-size: 90% 100%;
    background-position: bottom right;
  }
  .kate-pic{
    width: 100%;
  }
  .background-text{
    background-image: url(../assets/shadow.jpg), $white-overlay;
    background-blend-mode: lighten;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: bottom right;
    padding: 40px 0;
    margin: 5rem 10rem;
  }
}

// CONSTULATION SECTION STYLES

.form-flex{
  border-top: solid $dark-blue 2px;
  padding-top: 5vh;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  header{
    p{
      font-weight: normal;
    }
  }
  a {
    color: $dark-blue;
    text-decoration: none;
    -webkit-transition: color 0.25s;
    transition: color 0.25s;
    font-size: 1.5rem;
    font-weight: bold;
  }
  a:hover {
    color:$medium-green;
  }
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

form {
  max-width: 300px;
  margin: 0 auto;
  padding-left: 2rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

input[type="text"]{
  background-color: $baby-powder;
}

input[type="email"]{
  background-color: $baby-powder;
}

textarea {
  background-color: $baby-powder !important;
}
select {
  font-size: 1rem;
  background-color: $baby-powder !important;
}

textarea {
  height: 120px;
}

.btn:disabled{
  background-color: $medium-green;
  border: 1px solid $medium-green;
}

.loading-dots {
  margin: 0 auto;
  width: 50px;
  height: 20px;
  border-radius: 10px;
  background-color: $medium-green;
  transform: scale(0);
  animation: overall-scale 8s infinite;
}

.loading-dot {
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-name: dot-scale;
  animation-duration: 1s;
  
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 5px 0px;
  border-radius: 10px;
  background-color: white;
  transform: scale(0.65);
  
  &:first-of-type {
    margin-left: 6px
  }
  &:nth-of-type(2) {
    animation-delay: 0.15s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.3s;
  }
}

@keyframes dot-scale {
  0%, 70% { transform: scale(0.65); }
  35% { transform: scale(1); }
}

@keyframes overall-scale {
  0%, 95% { transform: scale(0); }
  5%, 90% { transform: scale(1); }
}

@media only screen and (min-width : 992px) {
  form {
    max-width: 500px;
  }
  .form-flex{
    flex-direction: row;
    justify-content: space-around;
    header{
      max-width: 40%;
      margin-top: 5rem;
    }
    form{
      width: 500px;
    }
  }
}

// FOOTER STYLES
.palm-5 {
  position: relative;
}

.footer{
  text-align: center;
  color: $baby-powder;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.footer-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.feedback {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  span {
    margin-right: 1rem;
  }
  a {
    text-decoration: none;
    color: $baby-powder;
    -webkit-transition: color 0.25s;
    transition: color 0.25s;
    font-size: 1rem;
  }
  a:hover {
    color:$medium-green;
  }
}

.copyright {
  margin-top: 1rem;
}