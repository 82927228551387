* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: black;
}

.site-wrapper {
  background-color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: light;
}

.container {
  padding-top: 10vh;
  margin-top: -10vh;
}

h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  margin-top: 2rem;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #8EC3A2;
}

h3 {
  color: #8EC3A2;
}

header {
  margin: 3rem;
}
header p {
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.btn {
  transition: color 0.25s;
  font-size: 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  width: 200px;
  padding: 10px;
  background-color: #F9F6F6;
  margin: 2rem;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.call-btn {
  width: 300px;
  background-color: #8EC3A2;
  border: none;
  transition: filter 0.25s;
}

.call-text {
  color: white;
}

.btn-flex {
  display: flex;
  justify-content: center;
}

.btn:hover {
  color: #8EC3A2;
  background-color: #F9F6F6;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.call-btn:hover {
  background-color: #8EC3A2;
  filter: brightness(1.1);
  border: none;
}

.display-none {
  display: none;
}

.image-section {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 20vh;
}

@media only screen and (min-width: 992px) {
  .image-section {
    background-attachment: fixed;
  }
}
.palm-1 {
  background-image: url(../assets/Backgrounds/palm-1.jpg), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  background-blend-mode: overlay;
}

.palm-2 {
  background-image: url(../assets/Backgrounds/palm-2.jpg), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  background-blend-mode: overlay;
}

.palm-3 {
  background-image: url(../assets/Backgrounds/palm-3.jpg), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;
}

.palm-4 {
  background-image: url(../assets/Backgrounds/palm-4.jpg), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  background-blend-mode: overlay;
}

.palm-5 {
  background-image: url(../assets/Backgrounds/palm-5.jpg), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  background-blend-mode: overlay;
}

nav {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  z-index: 1;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
}
nav .nav-link, nav .navbar-brand {
  color: #F9F4E9;
}
nav .navbar-toggler {
  background-color: #F9F4E9;
  border-radius: 20px;
}
nav .nav-link.active, nav .show > .nav-link, nav .nav-link:hover, nav .navbar-brand.active, nav .navbar-brand:hover {
  color: #8EC3A2 !important;
}
nav .dropdown-item {
  font-family: "Open Sans", sans-serif;
}
nav .dropdown-menu.show {
  max-width: 100px;
}

.video-container {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
}

.content {
  margin: 0 auto;
  position: absolute;
}

.content video {
  width: 100vw;
  display: block;
}

video {
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
}

.content:before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero {
  position: absolute;
  top: 40%;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  margin: 0;
  transform: translateY(-50%);
  color: #F9F6F6;
}
.hero h1 {
  font-weight: normal;
  font-size: 8rem;
  margin: 2rem auto;
}
.hero h2, .hero h3 {
  color: #F9F6F6;
}
.hero .dev {
  font-size: 4rem;
}
.hero a {
  text-decoration: none;
  color: inherit;
}

.hero-green {
  color: #8EC3A2 !important;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-10px);
  }
}
.hero-bottom {
  padding-top: 10vh;
}

.hero-large {
  display: none;
}

.hero-arrow {
  animation: bounce 0.75s ease infinite alternate;
  padding-top: 10vh;
  max-width: 75%;
  margin: 0 auto;
}

@media only screen and (min-width: 992px) {
  .hero {
    top: 55%;
  }
  .hero .hero-small {
    display: none;
  }
  .hero .hero-large {
    display: block;
  }
  .hero .hero-arrow {
    padding-top: 15vh;
  }
}
.services-wrapper {
  margin-top: 10vh;
}
.services-wrapper .services-text {
  margin-top: 2rem;
  font-size: 1.25rem;
  font-weight: normal;
}
.services-wrapper a {
  text-decoration: none;
}

.services-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.individual-service {
  width: 350px;
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.individual-service .service-icon {
  color: #8EC3A2;
  font-size: 3rem;
}
.individual-service .service-icon i {
  margin-right: 2rem;
}

#skills {
  margin-bottom: 2rem;
}
#skills img {
  margin: 0 auto;
}

.skill-icons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.skill-icons li {
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5%;
  margin: 20px 5px 5px 5px;
  width: 90px;
  height: 90px;
  align-items: center;
}
.skill-icons li i {
  font-size: 1.5rem;
}
.skill-icons li:hover {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
}
.skill-icons span {
  font-size: 0.8rem;
}

.cell {
  display: flex;
  flex-direction: column;
  background-color: #F9F6F6;
}

@media only screen and (min-width: 992px) {
  .skill-icons li {
    margin: 20px 10px 10px 10px;
    width: 110px;
    height: 110px;
    align-items: center;
  }
  .skill-icons li i {
    font-size: 2rem;
  }
}
.vertical-timeline-element-title, .vertical-timeline-element-subtitle {
  font-family: "Open Sans", sans-serif;
}

.vertical-timeline h3 {
  color: #8EC3A2;
}

.vertical-timeline::before {
  background: #172C3C;
}

.vertical-timeline-element-icon {
  box-shadow: none;
}

.hide-experience {
  text-align: left;
  width: 85%;
  margin: 1rem auto;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}

.vertical-timeline-element-content:hover .hide-experience {
  max-height: 800px;
  transition: max-height 0.75s ease-in;
}

.mac-svg {
  background-image: none;
  margin: 0 auto;
  position: relative;
  width: 100%;
  min-width: 300px;
  min-height: 275px;
}

@media only screen and (min-width: 768px) {
  .mac-svg {
    background-image: url(../assets/Projects/mac-screen.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    min-height: 530px;
  }
  .carousel-container {
    width: 100%;
    max-width: 616px;
  }
}
.carousel-container {
  width: 90%;
  max-width: 616px;
  height: 275px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 4%;
}

.carousel-cell {
  height: 100%;
}

.flickity-viewport {
  height: 275px;
}
.flickity-viewport img {
  max-width: 100%;
}

.flickity-page-dots {
  bottom: 10px;
}

.project {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 25px;
  background-color: #F9F6F6;
  margin: 2rem 0;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.project h3 {
  font-weight: bold;
}

.project-image {
  margin-top: 2rem;
}

.project-info {
  margin: 2rem;
}

.info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.info-wrapper .project-left, .info-wrapper .project-right {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.info-wrapper .project-left .demo, .info-wrapper .project-right .demo {
  font-size: 0.9rem;
}
.info-wrapper .project-left h3, .info-wrapper .project-right h3 {
  margin-top: 1rem;
}
.info-wrapper .project-left p, .info-wrapper .project-right p {
  margin-top: 1rem;
}
.info-wrapper .project-right {
  margin-top: 1rem;
}
.info-wrapper .project-icons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
.info-wrapper .project-icons .cell {
  background-color: white;
}
.info-wrapper .project-icons-wrapper {
  max-width: 400px;
  margin: 0 auto;
}
.info-wrapper .project-icons li {
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5%;
  margin: 10px 5px 5px 5px;
  width: 75px;
  height: 75px;
  align-items: center;
}
.info-wrapper .project-icons li i {
  font-size: 1.5rem;
}
.info-wrapper .project-icons li span {
  font-size: 0.75rem;
}
.info-wrapper .project-icons li:hover {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
}
.info-wrapper .project-links, .info-wrapper .project-left {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-left: 1rem;
}
.info-wrapper .project-links a, .info-wrapper .project-left a {
  text-decoration: none;
  color: #172C3C;
  transition: color 0.25s;
  font-size: 1.25rem;
  padding-right: 20px;
}
.info-wrapper .project-links p a, .info-wrapper .project-left p a {
  padding: 0;
}
.info-wrapper .project-links a:hover, .info-wrapper .project-left a:hover {
  color: #8EC3A2;
}

@media only screen and (min-width: 768px) {
  .carousel-container {
    height: 350.5px;
  }
  .flickity-viewport {
    height: 350.5px;
  }
  .info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .info-wrapper .project-left, .info-wrapper .project-right {
    width: 50%;
    max-width: 475px;
  }
}
.about-wrapper {
  min-height: 100vh;
}
.about-wrapper h2 {
  color: #172C3C;
}
.about-wrapper .about-title {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.about-wrapper header, .about-wrapper .about-title {
  border-bottom: solid white 3px;
}
.about-wrapper p {
  font-size: 1.25rem;
  margin: 1rem auto;
  max-width: 800px;
  text-align: left;
}

.about-text {
  max-width: 600px;
}

.about-text p, .background-text p {
  font-size: 1rem;
  margin: 1rem auto;
  max-width: 75%;
}

.about-flex {
  margin: 6rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.about-text {
  padding-bottom: 2rem;
}

.about-icon {
  color: #172C3C;
  font-size: 3rem;
  margin-right: 1rem;
}

.about-icon-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#about a, #more-about a {
  color: #172C3C;
  text-decoration: none;
  transition: color 0.25s;
  font-size: 1.5rem;
}
#about a:hover, #more-about a:hover {
  color: #8EC3A2 !important;
}
@media only screen and (max-width: 576px) {
  #about .about-flex, #more-about .about-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  #about a, #more-about a {
    font-size: 2rem;
  }
  #about .link-label, #more-about .link-label {
    display: none;
  }
  #about .call, #more-about .call {
    font-size: 1.75rem;
  }
}

.about-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

#more-about {
  padding: 0;
  margin: 0;
  width: 100%;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 25px;
  background-color: #F9F6F6;
  margin: 2rem 0;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
}

.card-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.about-background p, .about-education p {
  font-size: 1rem;
}
.about-background .card-wrapper, .about-education .card-wrapper {
  max-width: 900px;
  margin: 0 auto;
}
.about-background .card, .about-education .card {
  height: 210px;
  width: 270px;
  margin: 1rem auto;
}
.about-background .card .card-img-wrapper, .about-education .card .card-img-wrapper {
  padding-top: 10px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-background .card .card-title, .about-education .card .card-title {
  font-size: 1rem;
}
.about-background .card .card-subtitle, .about-education .card .card-subtitle {
  font-size: 0.75rem;
}
.about-background .card img, .about-education .card img {
  width: 100px;
  max-height: 150px;
  border: none;
  box-shadow: none;
  mix-blend-mode: darken;
}

.about-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.kate-pic {
  max-width: 100%;
  min-width: 275px;
}

.about-reading, .about-listening {
  text-align: center;
}
.about-reading .card-wrapper, .about-listening .card-wrapper {
  max-width: 825px;
  margin: 0 auto;
}
.about-reading .card, .about-listening .card {
  height: 350px;
  width: 225px;
  margin: 1rem auto;
}
.about-reading .card .card-header, .about-listening .card .card-header {
  margin-bottom: 1rem;
  background-color: #8EC3A2;
  color: white;
  font-size: 1.25rem;
  font-weight: 500;
}
.about-reading .card .card-img-wrapper, .about-listening .card .card-img-wrapper {
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-reading .card .card-title, .about-listening .card .card-title {
  font-size: 1rem;
}
.about-reading .card .card-subtitle, .about-listening .card .card-subtitle {
  font-size: 0.75rem;
}
.about-reading .card img, .about-listening .card img {
  max-width: 125px;
  max-height: 200px;
}

.about-listening .card {
  height: 275px;
}

.about-reading img, .about-listening img, .about-photography img {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.about-photography p {
  width: 80%;
}

.photo-wrapper {
  background-color: black;
  padding: 2rem;
}

.hide-btn {
  background-color: black;
}

.links {
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 2rem;
  max-width: 800px;
}
.links div {
  display: flex;
  justify-content: space-around;
  width: 100%;
  line-height: 0.5rem;
}
.links div + div {
  border-left: solid 2px #172C3C;
}

@media only screen and (min-width: 992px) {
  .about-flex {
    background-image: url(../assets/Backgrounds/palm-4.jpg), linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.88));
    filter: grayscale(100%);
    background-blend-mode: lighten;
    background-repeat: no-repeat;
    background-size: 90% 100%;
    background-position: bottom right;
  }
  .kate-pic {
    width: 100%;
  }
  .background-text {
    background-image: url(../assets/shadow.jpg), linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.88));
    background-blend-mode: lighten;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: bottom right;
    padding: 40px 0;
    margin: 5rem 10rem;
  }
}
.form-flex {
  border-top: solid #172C3C 2px;
  padding-top: 5vh;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}
.form-flex header p {
  font-weight: normal;
}
.form-flex a {
  color: #172C3C;
  text-decoration: none;
  transition: color 0.25s;
  font-size: 1.5rem;
  font-weight: bold;
}
.form-flex a:hover {
  color: #8EC3A2;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  font-weight: bold;
}

input[type=text],
input[type=email],
textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

form {
  max-width: 300px;
  margin: 0 auto;
  padding-left: 2rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

input[type=text] {
  background-color: #F9F6F6;
}

input[type=email] {
  background-color: #F9F6F6;
}

textarea {
  background-color: #F9F6F6 !important;
}

select {
  font-size: 1rem;
  background-color: #F9F6F6 !important;
}

textarea {
  height: 120px;
}

.btn:disabled {
  background-color: #8EC3A2;
  border: 1px solid #8EC3A2;
}

.loading-dots {
  margin: 0 auto;
  width: 50px;
  height: 20px;
  border-radius: 10px;
  background-color: #8EC3A2;
  transform: scale(0);
  animation: overall-scale 8s infinite;
}

.loading-dot {
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-name: dot-scale;
  animation-duration: 1s;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 5px 0px;
  border-radius: 10px;
  background-color: white;
  transform: scale(0.65);
}
.loading-dot:first-of-type {
  margin-left: 6px;
}
.loading-dot:nth-of-type(2) {
  animation-delay: 0.15s;
}
.loading-dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot-scale {
  0%, 70% {
    transform: scale(0.65);
  }
  35% {
    transform: scale(1);
  }
}
@keyframes overall-scale {
  0%, 95% {
    transform: scale(0);
  }
  5%, 90% {
    transform: scale(1);
  }
}
@media only screen and (min-width: 992px) {
  form {
    max-width: 500px;
  }
  .form-flex {
    flex-direction: row;
    justify-content: space-around;
  }
  .form-flex header {
    max-width: 40%;
    margin-top: 5rem;
  }
  .form-flex form {
    width: 500px;
  }
}
.palm-5 {
  position: relative;
}

.footer {
  text-align: center;
  color: #F9F6F6;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.footer-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.feedback {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.feedback span {
  margin-right: 1rem;
}
.feedback a {
  text-decoration: none;
  color: #F9F6F6;
  transition: color 0.25s;
  font-size: 1rem;
}
.feedback a:hover {
  color: #8EC3A2;
}

.copyright {
  margin-top: 1rem;
}/*# sourceMappingURL=main.css.map */